import {GraphQLClient} from 'graphql-request'
const token = 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjI3MzI4NjczOCwiYWFpIjoxMSwidWlkIjo0NTU5MTA0MSwiaWFkIjoiMjAyMy0wOC0wNVQyMzoxODozMC45OTRaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTU2OTYyNDAsInJnbiI6InVzZTEifQ.eHFZsvl7RoQ3ZA-nnohBKX-GRGctPI4KzEN49KwNPIs'
const endpoint = 'https://api.monday.com/v2/'

const graphQLClient = new GraphQLClient(endpoint, {
  headers: {
    Authorization: token
  }
})

export {
  graphQLClient
}
