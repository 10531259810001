import { graphQLClient } from './communication.js'
import gql from 'graphql-tag'



const FETCH_ACTIVE_SLIDES = gql`
  query {
    items_page_by_column_values(
      board_id: 5189469807,
      columns: [{column_id: "status", column_values: "Activo"}],
    ) {
      items {
        name
        column_values(ids: ["aviso", "archivo", "men__desplegable", "estado_1"]) {
          text
          column {
            title
          }
        }
      }
    }
  }
    
`

class Slide {
  constructor(item) {
    debugger
    this.department = item.column_values.find(i => i.column.title === 'Depto.').text
    this.announcement = item.column_values.find(i => i.column.title === 'Aviso').text
    this.type = item.column_values.find(i => i.column.title === 'Tipo').text
    this.asset = item.column_values.find(i => i.column.title === 'Archivo').text
    this.name = item.name
  }
}

export const parseSlides = (data) => data.items_page_by_column_values.items.map(s => new Slide(s))
export const getSlides = () => graphQLClient.request(FETCH_ACTIVE_SLIDES).then(parseSlides)



