import './App.css';
import { createContext, useState } from 'react'

import Slider from './components/Slider.js'
import Heading from './components/Heading.js'
import tegVid from './assets/vid.mp4'

// export const HeadingContext = createContext()

function App() {
  // const [headingTitle, setHeadingTitle] = useState()
  return (
    <div className="App">
      {/* <HeadingContext.Provider value={{headingTitle, setHeadingTitle}}> */}
        {/* <Heading/> */}
        <Slider/>
        <video muted autoPlay loop width='0px' height='0px' style={{position: 'fixed'}}>
          <source src={tegVid} type='video/mp4'/>
        </video>
      {/* </HeadingContext.Provider> */}
    </div>
  );
}

export default App;
