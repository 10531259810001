import react, { useEffect, useState, useRef, Fragment } from 'react'
import { getSlides } from '../Business/getSlides.js'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'
import flattenChildren from 'react-flatten-children'

import './slider.css'
import Slide from './Slide.js'

const splitLongSlides = (slide) => {
  const announcements = slide.announcement.split('\n\n')
  const announcementCap = 3
  const slides = []
  for(let i = 0; i < announcements.length; i += 1) {
    let slideBody = []
    for(let j = 0; j + i < announcements.length && j < announcementCap; j += 1) {
        if(announcements[i + j] === "") continue
        slideBody.push(announcements[i + j])
    }
    i += announcementCap
    if(slideBody.length == 0 ) continue
    slideBody = slideBody.map(t => `<p>${t}</p>`).join('')
    slides.push({...slide, announcement: slideBody})
  }
  return slides
}

const Slider = () => {
  const [slides, setSlides] = useState([])
  const updateSlides = () => {
    getSlides().then(setSlides)
  }

  useEffect(() => {
    updateSlides()
    return setInterval(updateSlides, 60000)
  }, [])

  
  const sliderBody = slides.length === 0 ? <div>Loading</div> : (
    <Carousel showThumbs={false} showStatus={false} autoPlay={true} interval={12000} infiniteLoop showArrows={false} stopOnHover={false}>
        {slides.map(slide => splitLongSlides(slide).map(slide => <Slide slide={slide}/>)).flat()}
    </Carousel>
  )

  return (
    <div className='c__slider'>
      {sliderBody}
    </div>
  )

}

export default Slider
