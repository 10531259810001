import React from 'react'
import logo from '../assets/tegsa-logo.png'

const Heading = ({title, subtitle}) => {
  return (
    <nav className='header'>
      <div className="header__left" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <img src={logo} alt="" />
      </div>
      <div className="header__center">
          <div style={{fontWeight: '800', fontSize: '25px'}}>{title}</div>
      </div>
      <div className="header__right">
          <div style={{margin: '0 1em', fontSize: '21px'}}>{subtitle}</div>
      </div>
    </nav>
  )
}

export default Heading
