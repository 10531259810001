import React, {useContext, Fragment} from 'react'
import panelBg from '../assets/panels.png'

import cumpleanos from "../assets/cumpleanos.png"
import cursos from "../assets/cursos.png"
import direccion from "../assets/direccion.png"
import incidentes from "../assets/incidentes.jpeg"
import indicador_insatisfactorio from "../assets/indicador_insatisfactorio.png"
import indicador_satisfactorio from "../assets/indicador_satisfactorio.png"
import logros from "../assets/logros.png"
import panels from "../assets/panels.png"
import saludseguridad from "../assets/saludseguridad.png"
import seguridad_ambiental from "../assets/seguridad_ambiental.jpg"
import auditorias from "../assets/auditorias.png"

// import {HeadingContext} from '../App.js'
import Heading from '../components/Heading.js'

const images = {
  General: 'url(' + panelBg + ')',
  ['Auditorías']:"linear-gradient(to right, #000A, #000A), url(" + auditorias +")",
  Cumpleaños:"linear-gradient(to right, #000A, #000A), url(" + cumpleanos +")",
  Cursos:"linear-gradient(to right, #000A, #000A), url(" + cursos +")",
  ['Dirección']:"linear-gradient(to right, #000A, #000A), url(" + direccion +")",
  Incidentes: "linear-gradient(to right, #000A, #000A), url(" + incidentes + ")",
  ['Indicadores Insatisfactorios']: "linear-gradient(to right, #000A, #000A), url(" + indicador_insatisfactorio + ")",
  ['Indicadores Satisfactorios']: "linear-gradient(to right, #000A, #000A), url(" + indicador_satisfactorio + ")",
  Logros: "linear-gradient(to right, #000A, #000A), url(" + logros + ")",
  Dashboard: "linear-gradient(to right, #000A, #000A), url(" + panels + ")",
  ['Salud y Seguridad']: "linear-gradient(to right, #000A, #000A), url(" + saludseguridad + ")",
  ['Seguridad y Ambiental']: "linear-gradient(to right, #000A, #000A), url(" + seguridad_ambiental + ")",
}

const AnnouncementSlide = ({slide}) => {

  const backgroundImage = images[slide.type]
  return (
    <Fragment>
      <Heading title={slide.name} subtitle={slide.department}/>
      <div className={'c__slide'} style={{backgroundImage}}>
        <div className='c__slide-body' dangerouslySetInnerHTML={{__html: slide.announcement}}>
        </div>
      </div>
    </Fragment>
  )
}

const DashboardSlide = ({slide}) => {
  const url = slide.announcement.replaceAll('<p>', '').replaceAll('</p>', '')
  console.log(slide)
  console.log(url)
  return (
  <Fragment>
    <Heading title={slide.name} subtitle={slide.department}/>
    <iframe src={url} style={{minHeight: '100%'}}></iframe>
  </Fragment>
  )
}

const slideRepo = {
  General: AnnouncementSlide,
  Dashboard: DashboardSlide
}

const Slide = ({slide}) => {

  const slideType = slide.type == 'Dashboard' ? slideRepo[slide.type] : slideRepo['General']

  return (
    React.createElement(
      slideType,
      {slide}
    )
  )

}


export default Slide
